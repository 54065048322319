/** @jsx jsx */
import { graphql } from "gatsby"
import { jsx } from "theme-ui"
import { Box, Flex, Heading } from "@theme-ui/components"
import Layout from "../components/organisms/Layout"
import BreadCrumbs from '../components/organisms/Breadcrumbs'
import SideNav from '../components/organisms/SideNav'
import Container from '../components/organisms/Container'
import ArticlesList from '../components/organisms/ArticlesList'
import { SEO as Seo  } from "../components"

const Topic = ({ data: { topic, articles, subTopics, subArticles } }) => {
  const { app } = topic

  const crumbs = [];
  crumbs.push({title: app.title, url: `/${app.slug}`})
  crumbs.push({ title: topic.title })

  return (
    <Box sx={{ pt: 3, ml: 4, mr: 6 }}>
      <BreadCrumbs crumbs={crumbs} />
      <Heading variant="h1" as="h1">{topic.title}</Heading>
      <ArticlesList supressTitle topic={topic} articles={articles.nodes} />
      {subTopics.nodes.map(t => <ArticlesList topic={t} articles={subArticles.nodes} />)}
    </Box>
  )
}

const TopicTemplate = ({ data, location }) => {
  const { topic } = data
  const search = {showSearch: true, filterApps: topic.app.slug}

  return (
    <Layout search={search}>
      <Seo datoSeo={topic.seo} pathname={location.pathname} />
      <Container>
        <Flex>
          <Box sx={{ minWidth: 300, }}>
            <SideNav appId={topic.app.id} topic={topic} location={location} />
          </Box>
          <Topic data={data} />
        </Flex>
      </Container>
    </Layout>
  )

}

export default TopicTemplate

export const topicQuery = graphql`
  query getTopic($recordId: String!) {
    topic: datoCmsTopic(id: {eq: $recordId}) {
      id
      title
      seo{
        description
        title
      }     
      slug
      app {
        id
        slug
        title
      }
    }

    subTopics: allDatoCmsTopic(filter: {parentTopic: {id: {eq: $recordId}}}) {
      nodes {
        id
        title
        slug
        app {
          id
          slug
          title
        }
      }
    }

    articles: allDatoCmsArticle(
      filter: {topic: {id: {eq: $recordId}}}
      sort: {fields: displayOrder, order: ASC}
      ) {
        nodes {
          id
          slug
          title
          topic {
            id
            slug
            title
            app {
              id
              slug
              title
            }
          }
        }
    }

    subArticles: allDatoCmsArticle(
      filter: {topic: {parentTopic: {id: {eq: $recordId}}}}
      sort: {fields: displayOrder, order: ASC}
      ) {
        nodes {
          id
          slug
          title
          topic {
            id
            slug
            title
            app {
              id
              slug
              title
            }
            parentTopic {
              id
              slug
              title
            }
          }
        }
    }
  }
`
